.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 10px 20px;
  }
  
  .navbar-logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .navbar-links {
    display: flex;
    gap: 20px;
  }
  
  .navbar-link {
    text-decoration: none;
    font-size: 18px;
    color: black;
  }
  