/* Home.css */
.home-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 20px;
  background: linear-gradient(to bottom, white, #8686FF);
  min-height: 100vh;
  font-family: 'Poppins', sans-serif; /* Modern font family */
}

.home-text {
  flex: 1;
  padding-right: 30px;
  margin-left: 200px;
}

.home-title {
  font-size: 3em; /* Increased font size */
  color: #333;
  font-weight: 600; /* Semi-bold weight */
  margin-bottom: 20px;
  margin-top: -10px;
}

.home-content {
  font-size: 1.2em; /* Slightly larger font size */
  line-height: 1.8; /* Improved line height */
  color: #555;
}

.home-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image img {
  max-width: 300px;
  height: auto;
}

.download-buttons {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.download-button {
  background-color: #8686FF;
  color: white;
  border: none;
  padding: 15px 30px;
  margin-right: 15px;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.download-button .icon {
  margin-right: 10px;
}

.download-button:hover {
  background-color: black;
  color: #8686FF;
}

/* Responsive adjustments for mobile devices */
@media screen and (max-width: 768px) {
  .home-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .home-text {
    text-align: center;
    order: 1;
    padding-right: 0;
    margin-left: 0;
  }

  .home-image {
    display: none;
  }

  .download-buttons {
    flex-direction: column;
    align-items: center;
  }

  .download-button {
    margin-right: 0;
    margin-bottom: 10px;
    width: 80%;
  }
}

/* Additional media query for very small screens */
@media screen and (max-width: 480px) {
  .home-title {
    font-size: 1.8em; /* Adjusted for smaller screens */
  }

  .home-content {
    font-size: 1.1em; /* Adjusted for smaller screens */
  }

  .download-button {
    padding: 10px 20px;
  }
}
