/* Footer.css */
.footer {
  text-align: center;
  padding: 20px;
  background: linear-gradient(to bottom, #8686FF, white);
  width: 100vw;
  height: auto;
  box-sizing: border-box;
  left: 0;
  right: 0;
}

.social-media-links a {
  margin: 0 10px;
  color: #000;
  font-size: 24px;
}

.social-media-links a:hover {
  color: #555;
}

/* Styling for the copyright text */
.footer .copyright {
  margin-top: 15px; /* Space above the copyright text */
  font-size: 14px; /* Adjust the font size as needed */
  color: #333; /* Adjust the color as needed */
}
