
/* PrivacyPolicy.css */
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.8;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.privacy-policy h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #8686ff;
  text-align: center;
}

.privacy-policy h2 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #8686ff;
}

.privacy-policy h3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #8686ff;
}

.privacy-policy h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 10px;
  color: #8686ff;
}

.privacy-policy p {
  margin-bottom: 20px;
  color: #666;
}

.privacy-policy ul,
.privacy-policy ol {
  margin-left: 30px;
  margin-bottom: 20px;
}

.privacy-policy li {
  margin-bottom: 15px;
  color: #666;
}

.privacy-policy a {
  color: #007bff;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.privacy-policy hr {
  margin-top: 40px;
  margin-bottom: 40px;
  border: 0;
  border-top: 1px solid #ddd;
}

.privacy-policy .highlight {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}